@mixin aspect-ratio($width, $height) {
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
  }
}


.ratio-1-1 {
  @include aspect-ratio(1, 1);
}

@font-face {
  font-family: 'silmastin';
  src: url('/assets/font/silmastin-aljjd-webfont.eot');
  src: url('/assets/font/silmastin-aljjd-webfont.eot?#iefix') format('embedded-opentype'),
  url('/assets/font/silmastin-aljjd-webfont.woff2') format('woff2'),
  url('/assets/font/silmastin-aljjd-webfont.woff') format('woff'),
  url('/assets/font/silmastin-aljjd-webfont.ttf') format('truetype'),
  url('/assets/font/silmastin-aljjd-webfont.svg#silmastinregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'RoughMotion';
  src: url('/assets/font/RoughMotion.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

html {
  cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAABFklEQVRYR9WXURLDIAhE6/0PbSdOtUpcd1Gnpv1KGpTHBpCE1/cXq+vrMph7dGvXZTtpfW10DCA5jrH1H0Jhs5E0hnZdCR+vb5S8Nn8mQCeS9BdSalYJqMBjAGzq59xAESN7VFVUgV8AZB/dZBR7QTFDCqGquvUBVVoEtgIwpQRzmANSFHgWQKExHdIrPeuMvQNDarXe6nC/AutgV3JW+6bgqQLeV8FekRtgV+ToDKEKnACYKsfZjjkam7a0ZpYTytwmgainpC3HvwBocgKOxqRjehoR9DFKNFYtOwCGYCszobeCbl26N6yyQ6g8X/Wex/rBPsNEV6qAMaJPMynIHQCoSqS9JSMmwef51LflTgCRszU7DvAGiV6mHWfsaVUAAAAASUVORK5CYII=), auto;

  --background: red;
}

*::selection {
  background-color: #DDF452;
  color: #000;
}

ion-page{
  background-image: rgb(2,0,36);
  background-image: url(/assets/img/Bg.png);
  background-image: url(/assets/img/Bg.png), radial-gradient(circle, rgba(10,10,10,1) 0%, rgba(25,25,25,1) 0%, rgba(10,10,10,1) 100%);
  max-height: 100vh;
  background-size: contain;
}

ion-content{
  --background : transparent;
}

.modal-wrapper {
  border: 1px solid #CFE24E!important;
  border-radius: 15px!important;
}

.shareModal{
  --height:300px!important;
  --min-height:300px!important;

  @media screen and (max-width: 545px) {
    --height:340px!important;
  }
  @media screen and (max-width: 480px) {
    --height:375px!important;
  }
  @media screen and (max-width: 340px) {
    --height:440px!important;
  }
}

ion-modal{
  --border-radius:15px;
  --border-color:#CFE24E;
  --border-width:1px;
  --background : #36382C;
  --border-style: solid;
  --max-width: 95%;

  .ion-page {
    --background : transparent!important;
    background-image : none!important;
  }
}



.tiblock {
  align-items: center;
  display: flex;
  height: 17px;
}

.ticontainer .tidot {
  background-color: #90949c;
}

.ticontainer{
  padding: 6.5px 0;
}

.tidot {
  -webkit-animation: mercuryTypingAnimation 1.05s infinite ease-in-out;
  animation: mercuryTypingAnimation 1.05s infinite ease-in-out;
  border-radius: 2px;
  display: inline-block;
  height: 4px;
  margin-right: 2px;
  width: 4px;
}

@keyframes mercuryTypingAnimation{
  0%{
    -webkit-transform:translateY(0px)
  }
  28%{
    -webkit-transform:translateY(-5px)
  }
  44%{
    -webkit-transform:translateY(0px)
  }
}

.tidot:nth-child(1){
  -webkit-animation-delay:200ms;
}
.tidot:nth-child(2){
  -webkit-animation-delay:300ms;
}
.tidot:nth-child(3){
  -webkit-animation-delay:400ms;
}


input {
  background-color: rgb(59, 59, 59);
}